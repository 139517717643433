$primaryColor: #0B7F34 !default;
$primaryLightColor: #6CE196 !default;
$primaryDarkColor: #036325 !default;
$primaryDarkerColor: #004218 !default;
$primaryTextColor: #ffffff !default;
$primaryContractColor: #ffffff !default;

$highlightBg: #EFF6FF !default;
$highlightTextColor: $primaryDarkerColor !default;
$highlightFocusBg: rgba($primaryColor, .24) !default;

@import '../_variables';
@import './_fonts';
@import '../../../../theme-base/_components';
@import '../_extensions';
