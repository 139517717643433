@import "@ng-select/ng-select/themes/default.theme.css";

@layer primeng, tailwind-utilities;

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;

  p > a {
    @apply text-primary no-underline font-bold;
  }

  li > a {
    @apply text-primary;
  }

  * {
    @apply decoration-brand-color;
  }

  img {
    @apply rounded-3xl;
  }

  .p-message-icon {
    @apply flex;
  }

  .p-message-close {
    @apply w-3.5 h-3.5;
  }

  .button-ghost-icon {
    @apply block text-left outline-none bg-transparent border-none shadow-none #{!important};
  }

  .button-ghost-icon .pi {
    @apply text-primary #{!important};
  }

  .p-message {
    @apply mt-0 mb-5;
  }

  #benchmarkTool .ng-select-container {
    @apply h-full cursor-text min-h-[55px] #{!important};
  }

  .ng-select .ng-clear-wrapper {
    @apply hidden #{!important};
  }

  .ng-dropdown-panel {
    //@apply border-none shadow-lg #{!important};
  }

  .ng-select .ng-select-container:hover {
    @apply shadow-none #{!important};
  }

  .ng-select .ng-arrow-wrapper {
    @apply hidden #{!important};
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  }

  .ng-select .ng-select-container .ng-value-container {
    @apply pl-10 text-base #{!important};
  }

  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    @apply pl-10 text-base #{!important};
  }

  .ng-select .ng-select-container .ng-value-container .ng-input > input {
    @apply text-base #{!important};
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    @apply bg-white p-4 text-base text-left #{!important};
  }

  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    @apply top-[16px] #{!important};
  }

  .ng-select .ng-select-container {
    //@apply border-none #{!important};
  }

  .ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    //@apply border-none shadow-none #{!important};
  }

  .ng-select .ng-select-container .ng-value-container .ng-placeholder {
  }
}

@import "primeflex/primeflex.css";
@import "primeicons/primeicons.css";
@import "primeng/resources/primeng.css";
@import "theme/themes/lara/lara-light/blue/theme.scss";

html {
  scroll-behavior: smooth;
}

.p-tooltip>.p-tooltip-text {
  width: 350px !important;
}

html, body {
  height: 100%;
}

.grid {
  margin-right: -1rem;
  margin-left: -1rem;
  margin-top: -1rem;
}

.grid > .col, .grid > [class*=col] {
  padding: 1rem;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  cursor: text !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 250px !important;
}

.p-avatar {
  img {
    object-fit: cover;
  }
}
